import { render, staticRenderFns } from "./Medical.vue?vue&type=template&id=29465528&scoped=true&"
var script = {}
import style0 from "./Medical.vue?vue&type=style&index=0&id=29465528&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29465528",
  null
  
)

export default component.exports